import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterThree from "../component/footer/FooterThree";
import HeaderThree from "../component/header/HeaderThree";
import {Redirect} from "react-router-dom";

//TODO: change from hackerrank to coding challenges
class HackerRank extends Component{
    constructor(props) {
        super(props)
        this.state = {
            openedHR: false
        }
        this.openModal = this.openModal.bind(this)
    }

    componentWillMount() {
        window.open("https://www.hackerrank.com/devinwallace", "_blank");
        this.openedHR = true;
    }

    componentDidMount() {

    }

    openModal () {
        this.setState({isOpen: true})
    }

    render(){
        if (this.openedHR){
            return <Redirect to={'/'} />

        }
    }
}
export default HackerRank;