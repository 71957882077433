import React, { Component } from "react";
import {FaTwitter, FaInstagram, FaLinkedinIn, FaGitlab, FaCode, FaHackerrank} from "react-icons/fa";
import { FiX , FiMenu} from "react-icons/fi";
import Scrollspy from 'react-scrollspy'

const SocialShare = [
    {Social: <FaGitlab /> , link: 'https://gitlab.com/Devin-Wallace'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/in/devin-wallace-34167998/'},
    {Social: <FaHackerrank /> , link: 'https://www.hackerrank.com/devinwallace'},
    {Social: <FaCode /> , link: 'https://gitlab.com/Devin-Wallace/personal-site'},
]
class HeaderThree extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
        
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    stickyHeader () {}

    render(){

        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            try{
                if (value > 100) {
                    document.querySelector('.header--fixed').classList.add('sticky')
                }else{
                    document.querySelector('.header--fixed').classList.remove('sticky')
                }
            }catch (TypeError){
                setInterval('',500);

            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const { logo, color='default-color' } = this.props;
        let logoUrl;
        if(logo === 'light'){
            logoUrl = <img src="/assets/images/logo/33006710_VIB_2.ico" alt="Digital Agency" />;
        }else if(logo === 'dark'){
            logoUrl = <img src="/assets/images/logo/33006710_VIB_2.ico" alt="Digital Agency" />;
        }else if(logo === 'symbol-dark'){
            logoUrl = <img src="/assets/images/logo/33006710_VIB_2.ico" alt="Digital Agency" />;
        }else if(logo === 'symbol-light'){
            logoUrl = <img src="/assets/images/logo/33006710_VIB_2.ico" alt="Digital Agency" />;
        }else{
            logoUrl = <img src="/assets/images/logo/33006710_VIB_2.ico" alt="Digital Agency" />;
        }
        
        return(
            <header className="header-area header-style-two header--fixed navbar_two">
                <div className="header-wrapper">
                    <div className="header-left d-flex align-items-center">
                        <div className="logo">
                            <a href={this.props.homeLink}>
                                {logoUrl}
                            </a>
                        </div>
                        <nav className="mainmenunav d-lg-block ml--50" >
                            <Scrollspy className="mainmenu" items={['home','about','projects','recent','contact']} currentClassName="is-current" offset={-200}>
                                <li><a href="javascript:document.getElementById('home').scrollIntoView(true)">Home</a></li>
                                <li><a href="javascript:document.getElementById('about').scrollIntoView(true)">About</a></li>
                                <li><a href="javascript:document.getElementById('projects').scrollIntoView(true)">Projects</a></li>
                                <li><a href="javascript:document.getElementById('recent').scrollIntoView(true)">Recent Work</a></li>
                                <li><a href="javascript:document.getElementById('contact').scrollIntoView(true)">Contact</a></li>
                                <li><a href='https://dwallace-site-bucket.s3.amazonaws.com/docs/new_resume.pdf' target='_blank'>Resume</a></li>
                            </Scrollspy>
                        </nav>
                    </div>
                    <div className="header-right">
                        <div className="social-share-inner">
                            <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                                {SocialShare.map((val , i) => (
                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default HeaderThree;