import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import PageScrollTop from './component/PageScrollTop';
import DarkPortfolioLanding from './pages/PortfolioLanding';
import con from './pages/construction';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import HackerRank from "./pages/HackerRank";
import Gitlab from "./pages/Gitlab";
import DevbotSite from "./pages/DevbotSite";
import error404 from "./elements/error404";
import C3StreamingService from "./pages/C3StreamingService";

class Root extends Component{
    render(){
        return(
            <BrowserRouter>
                <PageScrollTop>
                    <Switch>
                        <Route exact path='/' component={DarkPortfolioLanding}/>
                        <Route path={`${process.env.PUBLIC_URL}/Java`} component={con} />
                        <Route path={`${process.env.PUBLIC_URL}/AWS`} component={con} />
                        <Route path={`${process.env.PUBLIC_URL}/HackerRank`} component={HackerRank} />
                        <Route path={`${process.env.PUBLIC_URL}/React`} component={con} />
                        <Route path={`${process.env.PUBLIC_URL}/Python`} component={con} />
                        <Route path={`${process.env.PUBLIC_URL}/Gitlab`} component={Gitlab} />
                        <Route path={`${process.env.PUBLIC_URL}/DevbotSite`} component={DevbotSite} />
                        <Route path={`${process.env.PUBLIC_URL}/C3StreamingService`} component={C3StreamingService} />



                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();