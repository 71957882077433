import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let
        tab1 = "Experience",
        tab2 = "Education & Certification",
        tab3 = "resume";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a>Software Developer <span> - State Farm</span></a> 2020 - Current
                                               </li>
                                               <li>
                                                   <a>Cyber Security Analyst<span> - Air Force Reserves</span></a> 2017 - Current
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="https://aw.certmetrics.com/amazon/public/verification.aspx?mainContent_txtVerificationCode=887J142DB1Q41LKR" target="_blank">AWS Certified Cloud Practitioner<span> - AWS </span></a> Cert ID: 887J142DB1Q41LKR
                                               </li>
                                               <li>
                                                   <a href="https://www.hackerrank.com/certificates/a0b876fdc6c0" target={"_blank"}>C++<span> - HackerRank </span></a> Cert ID: a0b876fdc6c0
                                               </li>
                                               <li>
                                                   <a> BA Computer Science & BS Mechanical Engineering <span> - CU Boulder</span></a>2022
                                               </li>
                                               <li>
                                                   <a> AA Computer Science<span> - Lincoln Land Community College</span></a>2020
                                               </li>

                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content" >
                                            <ul>
                                                <li>
                                                    <button className="rn-button-style--2 btn-solid" value="submit" type="button"><a href='https://dwallace-site-bucket.s3.amazonaws.com/docs/new_resume.pdf' target="_blank">Click here to view PDF Version</a></button>
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;