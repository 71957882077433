import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import FooterThree from "../component/footer/FooterThree";

class DevbotSite extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <div className="active-dark-subpage">
                <PageHelmet/>
                <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />
                
                {/* Start Breadcrump Area */}
                <div className=" pt--120 pb--190 d-flex align-items-center justify-content-center bg_image desk">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">www.0xdevbot.com</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        {/*<div className="thumbnail">*/}
                                        {/*    <img src="/assets/images/blog/bl-big-01.jpg" alt="Blog Images"/>*/}
                                        {/*</div>*/}
                                        <p><br/>This is the project writeup for the current website you are viewing. I know, pretty meta. </p>
                                        <p>This project will likely only always be left in the WIP status as i'll be updating the site as I progress as a developer.</p>
                                        <div className="blog-double-list-wrapper d-flex flex-wrap">
                                            <div className="thumbnail-inner" >
                                                <img  src="/assets/images/portfolio/0xdevbotDiagram.png" alt=""/>
                                                <span className="d-flex justify-content-center">www.0xdevbot.com working diagram</span>
                                            </div>
                                            <div className="content">
                                                <h4 style={{color: "black"}}>Technologies used and explanation</h4>
                                                <ul className="list-style">
                                                    <li>React</li>
                                                        <ul>
                                                            <li>The reason I used react was that I have the most experience with react when compaired to other possible solutions. Furthermore, given the use case React would be the best use. </li>
                                                        </ul>
                                                    <li>AWS</li>
                                                        <ul>
                                                            <li>The ability to create serverless apps was paramount in this decision. Not only that having control over all the tools I need in one spot is very helpful.</li>
                                                        </ul>
                                                    <li>Route53</li>
                                                        <ul>
                                                            <li>I used Route53 for DNS, Certs, and domain registration. </li>
                                                        </ul>
                                                    <li>Cloudfront</li>
                                                        <ul>
                                                            <li>Cloudfront is used here to connect my S3 bucket to Route53 and vice versa</li>
                                                        </ul>
                                                    <li>S3 one-zone IA</li>
                                                        <ul>
                                                            <li>I chose to go with the S3 one-zone IA for cost savings and because I expect this site will have less than 25 people viewing it at once and less then 100 viewing it per month. </li>
                                                        </ul>
                                                    <li>Lambda</li>
                                                        <ul>
                                                            <li>Lambdas use case here is for two things. </li>
                                                            <oli>
                                                                <li>Taking the master branch from my Gitlab project and pushing it to the proper S3 buckets</li>
                                                                <li>Taking the JSON objects that are pushed from CI/CD and ordering them in my /resources bucket so that I can dynamically update the site as new projects are added and updated</li>
                                                            </oli>
                                                        </ul>
                                                    <li>API Gateway</li>
                                                        <ul>
                                                            <li>Must be used with Lambda, but primarily setting up my API endpoints</li>
                                                        </ul>
                                                    <li>Gitlab w/ CI/CD</li>
                                                        <ul>
                                                            <li>Could have used Github for this project but I prefer Gitlab.</li>
                                                            <li>The CI/CD is a pivotal point for the project, when a master branch is updated it pushes changes automatically to AWS hitting the API Gateway endpoints.</li>
                                                        </ul>
                                                </ul>
                                            </div>
                                        </div>

                                        {/*<p className="mt--25 mt_sm--5">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet. It re are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injectedeed eedhumour, or randomised words which don't look even slightly believable.</p>*/}
                                        {/*<div className="video-wrapper position-relative mb--40">*/}
                                        {/*    <div className="thumbnail">*/}
                                        {/*        <img src="/assets/images/blog/bl-big-01.jpg" alt="Blog Images"/>*/}
                                        {/*    </div>*/}
                                        {/*    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />*/}
                                        {/*    <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>*/}
                                        {/*</div>*/}
                                        {/*<p className="mb--0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet. It re are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injectedeed eedhumour, or randomised words which don't look even slightly believable.</p>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterThree />
                </div>
            </React.Fragment>
        )
    }
}
export default DevbotSite;