import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";
import {FaAws, FaGitlab, FaHackerrank, FaJava, FaPython, FaReact} from "react-icons/fa";
import {DiCode, DiPython, DiReact} from "react-icons/all";
import Java from "../../pages/Java";
import { Link } from 'react-router-dom';

const ServiceList = [
    {
        icon: <FaJava />,
        title: 'Java',
        description: 'Here you can find any projects that use Java',
        link: '/Java'
    },
    {
        icon: <FaAws />,
        title: 'AWS',
        description: 'Here you can find any projects that use AWS to include Terraform',
        link: './AWS'
    },
    {
        icon: <FaHackerrank />,
        title: 'HackerRank',
        description: 'See all my HackerRank problem solutions',
        link: './HackerRank'
    },
    { 
        icon: <FaReact />,
        title: 'React',
        description: 'Here you can find any projects that use React',
        link: './React'
    },
    {
        icon: <FaPython />,
        title: 'Python',
        description: 'Here you can find any projects that use Python to include any AI/ML/DL projects',
        link: './Python'
    },
    { 
        icon: <FaGitlab />,
        title: 'All projects on Gitlab',
        description: 'View all finished projects on Gitlab',
        link: './Gitlab'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <Link to={val.link}>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                           </Link>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
