import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterThree from "../component/footer/FooterThree";
import HeaderThree from "../component/header/HeaderThree";


class Gitlab extends Component{
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    componentWillMount() {
        window.open("https://gitlab.com/Devin-Wallace", "_blank");
        window.location.href = "http://localhost:3000/";
    }

    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <div className="active-dark-subpage">
                    <button><a href="http://localhost:3000/">click here to return to the home page</a></button>
                </div>
            </React.Fragment>
        )
    }
}
export default Gitlab;